import React from 'react';

function Star(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path
        d="M8 1.3a6.7 6.7 0 100 13.4A6.7 6.7 0 008 1.3zm2.1 10.3L8 10.3l-2.1 1.3c-.3.2-.6-.1-.5-.4L6 8.8 4 7.2c-.2-.2-.1-.6.2-.6l2.5-.2 1-2.3c.1-.3.5-.3.6 0l1 2.3 2.5.2c.3 0 .4.4.2.6l-1.9 1.6.6 2.4c0 .3-.3.5-.6.4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default Star;
