import React from 'react';

function SubscriptionDone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218 128" {...props}>
      <path
        d="M4.1 110.1h3.8c1.7 0 3 1.3 3 3v3.8c0 1.7-1.3 3-3 3H4.1c-1.7 0-3-1.3-3-3v-3.8c0-1.6 1.4-3 3-3z"
        fill="none"
        stroke="#1a212a"
        strokeWidth={2.25}
      />
      <path
        d="M31 27h-1.5c-1.7 0-3-1.3-3-3v-2c0-1.7-1.3-3-3-3H22h0"
        fill="none"
        stroke="#286efb"
        strokeWidth={2.25}
        strokeLinecap="round"
      />
      <path
        d="M202.8 70.5l1.9 7.5c.4 1.7 2.3 2.8 4.1 2.3l5.5-1.4h0l1.9-.5"
        fill="none"
        stroke="#fd6eb3"
        strokeWidth={2.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(48)">
        <circle cx={63.8} cy={63.8} r={63.8} fill="#d6f6e3" />
        <circle cx={63.8} cy={63.8} r={53.2} fill="#31d574" />
        <path fill="#fff" d="M55.9 85.7L40.4 65.8l9-7 7.4 9.4L81.6 42l8.2 7.9z" />
      </g>
    </svg>
  );
}

export default SubscriptionDone;
