import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={512} height={512} {...props}>
      <path
        fill="currentColor"
        d="M420.94 179.879c.002.377.06.743.06 1.121 0 115.796-100.928 210-225 210h-63.832c26.71 70.695 98.853 121 183.832 121h181c6.167 0 11.704-3.779 13.96-9.521s.762-12.275-3.765-16.479l-41.03-38.057C495.843 415.525 512 373.905 512 331c0-62.157-36.078-118.524-91.06-151.121z"
      />
      <path
        fill="currentColor"
        d="M4.805 334.999A15 15 0 0015 361h181c107.52 0 195-80.742 195-180S303.52 0 196 0 0 81.742 0 181c0 42.905 16.157 83.525 45.835 115.942l-41.03 38.057zM106 91h180c8.291 0 15 6.709 15 15s-6.709 15-15 15H106c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15H106c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15H106c-8.291 0-15-6.709-15-15s6.709-15 15-15z"
      />
    </svg>
  );
}

export default SvgComponent;
