import React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path
        d="M2 11.6v2c0 .3.1.4.3.4h2c.1 0 .2 0 .2-.1l7.3-7.3-2.4-2.5-7.3 7.3c-.1.1-.1.1-.1.2zm11.8-6.9c.3-.3.3-.7 0-.9l-1.6-1.6c-.3-.3-.7-.3-.9 0l-1.2 1.2 2.5 2.5 1.2-1.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
