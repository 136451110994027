import React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path
        d="M10.2 1H3.3C2.6 1 2 1.6 2 2.3v8.3c0 .4.3.6.6.6s.6-.3.6-.6V2.9c0-.4.3-.6.6-.6h6.3c.3 0 .6-.3.6-.6s-.1-.7-.5-.7zM14 4.9v8.8c0 .7-.6 1.3-1.3 1.3h-7c-.7 0-1.3-.6-1.3-1.3V4.8c0-.7.6-1.3 1.3-1.3h6.9c.8 0 1.4 1 1.4 1.4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
